var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-5 basket-wrapper-b basket-uk"},[_c('header',{staticClass:"is-flex is-justify-content-space-between is-align-items-center mb-4"},[_c('h3',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("basket.headline"))+" ")]),_c('button',{staticClass:"button is-transparent p-0 mb-0",on:{"click":function($event){return _vm.setSidebarOpen(false)}}},[_c('CloseIcon')],1)]),_c('div',{staticClass:"basket-container"},[_c('div',[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-flex-end"},[_c('div',{staticClass:"basket-wrapper"},[_c('div',{staticClass:"basket-title icon-row"},[_c('span',{staticClass:"basket-title-span-holder"},[_c('span',[_vm._v(_vm._s(_vm.$t("basket.title")))])]),(
                  _vm.selectedRegionScoped == 'UK' && _vm.helpMeChooseScoped == true
                )?_c('span',[(_vm.isNuxt == true)?_c('span',{staticClass:"help-choose",on:{"click":_vm.closeBasket}},[_vm._v(" "+_vm._s(_vm.$t("basket.helpMeChoose"))+" ")]):_vm._e(),(_vm.isNuxt != true)?_c('span',{staticClass:"help-choose",on:{"click":_vm.closeBasket}},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: this.seeMoreLink.hash }),expression:"{ el: this.seeMoreLink.hash }"}],staticClass:"underlined",attrs:{"to":{
                      hash: this.seeMoreLink.hash,
                    }}},[_vm._v(_vm._s(_vm.$t("basket.helpMeChoose")))])],1):_vm._e()]):_vm._e()]),_c('div',{class:'separator--light my-4'}),_c('div',{staticClass:"basket-vaha-items"},_vm._l((_vm.products),function(product){return _c('div',{key:product.type,staticClass:"basket-vaha-item",class:{
                  active: _vm.$t(_vm.selectedProduct.id) == _vm.$t(product.id),
                },on:{"click":function($event){_vm.changeProduct(product, _vm.$t(product.id), _vm.selectedRegionScoped)}}},[[_c('div',{staticClass:"basket-vaha-item-image-wrapper basket-vaha-item-image-wrapper--uk"},[_c('img',{attrs:{"src":product.image}}),_c('h6',{staticClass:"basket-product-title strong font-16 mt-2"},[_vm._v(" "+_vm._s(_vm.$t(product.name))+" ")])]),_c('div',{staticClass:"basket-vaha-item-product-name"},[_c('p',{staticClass:"basket-price mt-3 mb-3 has-text-strikethrough",staticStyle:{"font-size":"14px","text-decoration-color":"red"}},[_vm._v(" "+_vm._s(_vm.$t(product.prices.direct.price))+" ")]),_c('p',{staticClass:"strong font-18 basket-price mt-3 mb-3",staticStyle:{"font-size":"24px"}},[_vm._v(" "+_vm._s(_vm.$t(product.prices.direct.discountPrice))+" ")])])]],2)}),0),_c('div',{class:'separator--light my-4'})])]),_c('PromoBox',{attrs:{"variant":_vm.promoBoxVariant,"showYellowBox":_vm.basketVersion !== 'UK' ? true : false,"selectedProduct":_vm.selectedProduct,"region":_vm.selectedRegionScoped,"experimentRunning":_vm.experimentRunning}}),_c('div',{class:'separator--light my-4'}),_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-flex-end"},[_c('BasketCollapse',{attrs:{"id":'MEMBERSHIP',"title":_vm.$t(_vm.selectedProduct.membership.headline),"icon":'medal'}},[_c('div',{staticClass:"basket-wrapper"},[_c('div',{staticClass:"basket-list-price-wrapper is-justify-content-space-between"},[_c('ul',{staticClass:"basket-price-summary"},_vm._l((_vm.selectedProduct.membership
                      .bulletPoints),function(bulletpoint){return _c('li',{key:bulletpoint},[_vm._v(" "+_vm._s(_vm.$t(bulletpoint))+" ")])}),0),(_vm.selectedRegionScoped == 'UK')?_c('div',{staticClass:"has-text-right is-flex-shrink-0"},[_c('span',{staticClass:"basket-price"},[_vm._v(" "+_vm._s(_vm.$t(_vm.selectedProduct.membership.price))+" "),(_vm.$t(_vm.selectedProduct.membership.pricePeriod))?_c('span',{staticClass:"basket-price-period"},[_vm._v(" / "+_vm._s(_vm.$t(_vm.selectedProduct.membership.pricePeriod))+" ")]):_vm._e()])]):_vm._e()])])])],1),_c('div',{class:'separator--light my-4'}),(_vm.activePromotion === 'NO_DISCOUNT')?_c('NoDiscount',{attrs:{"icon":'star-promo',"region":_vm.selectedRegionScoped}}):_vm._e()],1),_c('div',{class:{ fixed: _vm.experimentRunning }},[(
            _vm.selectedProductDiscountedPrice != null ||
              _vm.$te(_vm.discount.code.promoCode)
          )?_c('div',{class:'separator--light my-4'}):_vm._e(),(
            _vm.selectedProductDiscountedPrice != null ||
              _vm.$te(_vm.discount.code.promoCode)
          )?_c('BasketDiscountRow',{attrs:{"discount":_vm.discount}}):_vm._e(),_c('div',{class:'separator--light my-4'}),_c('BasketPricesRow',{attrs:{"prices":_vm.prices,"selectedRegion":_vm.selectedRegionScoped,"unique-discount-selected-product":_vm.selectedProductDiscountedPrice}}),(_vm.selectedProduct.paymentButton.url)?_c('AppIconButton',{staticClass:"basket-payments-button",attrs:{"theme":"yellow","title":_vm.$t(_vm.selectedProduct.paymentButton.label)},on:{"click":function($event){return _vm.prepareShopifyUrl(_vm.selectedProduct.id, _vm.selectedRegionScoped)}}}):_vm._e()],1),_c('div',{staticClass:"basket-payments-container"},_vm._l((_vm.allowedPayments),function(allowedPayment){return _c('img',{key:allowedPayment.name,staticClass:"basket-payments-icon",attrs:{"src":_vm.prepareAllowedPaymentName(allowedPayment.name),"alt":("" + (allowedPayment.name))}})}),0),_c('div',{class:'separator--light my-4'}),_c('PageSectionContainer',{staticClass:"has-background-white pb-5 mb-big",attrs:{"container":""}},[_c('ul',{staticClass:"columns is-multiline is-mobile mt-2 mb-1"},_vm._l((_vm.selectedProduct.featuredServices.items),function(item){return _c('li',{key:item.name,staticClass:"column is-12-mobile is-12-tablet is-4-desktop"},[_c('span',{staticClass:"vaha-item delivery-notes",class:{ 'experiment-13-09': _vm.experimentRunning }},[_c('span',{staticClass:"vaha-icon"},[_c(("Icon" + (_vm.$t(item.id))),{tag:"component",class:("vaha-icon-" + (_vm.$t(item.id)))})],1),_c('span',{staticClass:"vaha-text has-text-centered-desktop"},[_vm._v(" "+_vm._s(_vm.$t(item.content))+" ")])])])}),0)]),(_vm.klarnaNon0Enabled)?[_c('div',{staticClass:"klarna-terms font-14 has-text-centered",staticStyle:{"margin-top":"-20px"}},[_vm._v(" *in case of installment purchase the interest charged by Klarna for monthly installments will apply. ")])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }