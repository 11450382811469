<template>
  <section class="width-100 is-flex mt-3 mb-3 is-justify-content-space-between">
    <template v-if="selectedRegion !== 'AE'">
      <div class="font-700">
        <p
          v-if="selectedRegion === 'DE' || selectedRegion === 'AT'"
          class="font-16 has-text-black mr-2 line-height-21"
        >
          Gesamtbetrag
        </p>
        <p v-else class="font-16 has-text-black mr-2 line-height-21">Total</p>
      </div>
      <div class="font-700 has-text-right is-flex is-flex-direction-column">
        <div
          class="is-flex line-height-21 is-align-items-flex-end is-justify-content-flex-end"
        >
          <p
            v-if="$te(prices.priceBefore)"
            class="font-16 has-text-black mr-2 has-text-strikethrough line-height-21"
          >
            {{ $t(prices.priceBefore) }}
          </p>
          <p
            v-if="uniqueDiscount.selectedProductBasePriceFormatted != null"
            class="font-16 has-text-black mr-2 has-text-strikethrough line-height-21"
          >
            {{ uniqueDiscount.selectedProductBasePriceFormatted }}
          </p>

          <p
            v-if="!uniqueDiscountSelectedProduct && $te(prices.priceAfter)"
            class="font-22 has-text-primary line-height-21"
          >
            {{ $t(prices.priceAfter) }}
          </p>
          <p
            v-else-if="uniqueDiscountSelectedProduct"
            class="font-22 has-text-primary line-height-21"
          >
            {{ uniqueDiscountSelectedProduct }}
          </p>
        </div>
        <span
          class="font-14 has-text-primary"
          v-if="selectedRegion === 'DE' || selectedRegion === 'AT'"
          >(inkl. MwSt.)</span
        >
        <span class="font-14 has-text-primary" v-else>(incl. VAT)</span>
        <div
          class="is-flex line-height-21 is-align-items-flex-end is-justify-content-flex-end mt-2 mb-2"
        >
          <p
            v-if="!uniqueDiscountSelectedProduct"
            class="font-14 has-text-black line-height-21"
          >
            <!-- <span v-if="selectedRegion === 'UK'">for 12 months</span> -->
            <span v-if="selectedRegion === 'DE' || selectedRegion === 'AT'"
              >für 12 Monate
              <!-- <span @click="handleModalOpen(true)"> <InfoIcon /></span -->
            </span>
            <template v-if="selectedRegion === 'UK'">
              £
              {{
                (
                  parseInt(
                    $t(prices.priceAfter)
                      .replace(/[€,]+/g, "")
                      .replace(/[£,]+/g, "")
                      .replace(/[.,]+/g, ""),
                    10
                  ) / 36
                ).toFixed(0)
              }}</template
            >
            <!-- <template v-if="selectedRegion === 'DE' || selectedRegion === 'AT'"> € / monatlich</template>-->
            <template v-if="selectedRegion === 'UK'"> / mtl.</template>
          </p>
          <!-- <p
          v-else-if="uniqueDiscountSelectedProduct"
          class="font-22 line-height-21 has-text-black"
        >
          <span v-if="selectedRegion === 'DE' || selectedRegion === 'AT'">ab</span>
          <span v-else-if="selectedRegion === 'UK'">from £</span
          >{{
            (
              parseInt(
                uniqueDiscountSelectedProduct
                  .replace(/[€,]+/g, "")
                  .replace(/[£,]+/g, "")
                  .replace(/[.,]+/g, ""),
                10
              ) / 36
            ).toFixed(0) == 44
              ? "45"
              : (
                  parseInt(
                    uniqueDiscountSelectedProduct
                      .replace(/[€,]+/g, "")
                      .replace(/[£,]+/g, "")
                      .replace(/[.,]+/g, ""),
                    10
                  ) / 36
                ).toFixed(0)
          }}
          <template v-if="selectedRegion === 'DE' || selectedRegion === 'AT'"> € / monatlich</template>
          <template v-if="selectedRegion === 'UK'"> / mo</template>
        </p> -->
        </div>
      </div>
      <!-- <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <template>
            Um deine Bestellung abzuschließen, <br />
            wird dir die erste Rate von 199 € in Rechnung gestellt. <br />
            Deine monatlichen Zahlungen in Höhe von 199 € beginnen 30 Tage
            <br />
            nach der Lieferung deines VAHA für einen Zeitraum von 12 Monaten,
            <br />
            einschließlich deiner ersten Rate (insgesamt 12 Zahlungen).
            <br />
            Nach Ablauf der ersten 12 Monate reduzieren sich deine monatlichen
            Raten auf nur 49 €/Monat. <br />
            Die Mitgliedschaft ist zunächst auf 12 Monate befristet.
          </template>
        </div>
      </div>
    </b-modal> -->
    </template>
    <template v-if="selectedRegion === 'AE'">
      <div class="font-700">
        <p class="font-16 has-text-black mr-2 line-height-21">Total</p>
      </div>
      <div class="font-700 has-text-right is-flex is-flex-direction-column">
        <div
          class="is-flex line-height-21 is-align-items-flex-end is-justify-content-flex-end"
        >
          <p
            v-if="!uniqueDiscountSelectedProduct && $te(prices.priceAfter)"
            class="font-22 has-text-primary line-height-21"
          >
            AED 9,375
          </p>
        </div>

        <span class="font-14 has-text-primary">(incl. VAT)</span>
      </div>
    </template>
  </section>
</template>

<script>
import { store } from "../store";
// import InfoIcon from "../icons/info-icon";
export default {
  name: "BasketPricesRow",
  props: {
    prices: { type: Object, default: () => {} },
    selectedRegion: { type: String, default: "DE" },
    uniqueDiscountSelectedProduct: { type: String, default: null },
  },
  // components: {
  //   InfoIcon,
  // },
  data() {
    return {
      isCardModalActive: false,
    };
  },
  methods: {
    handleModalOpen() {
      console.log("click");
      this.isCardModalActive = true;
    },
  },
  computed: {
    uniqueDiscount() {
      return store.state.uniqueDiscount;
    },
    selectedProduct() {
      return store.state.sel;
    },
    // price36m() {

    // }
  },
};
</script>

<style lang="scss">
.width-100 {
  width: 100%;
}

.font-700 {
  font-weight: 700;
}

.font-16 {
  font-size: 16px;
  line-height: 150%;
}

.font-22 {
  font-size: 22px;
  line-height: 150%;
}

.has-text-black {
  color: black;
}

.has-text-primary {
  color: #f69e1e;
}

.has-text-grey {
  color: #222;
}

.has-text-strikethrough {
  text-decoration: line-through;
}

.line-height-21 {
  line-height: 21px !important;
}
</style>
