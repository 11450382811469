// staging UK values
export const stagingUKvahaXclassicVariantID = "41455358574785";
export const stagingUKvahaSclassicVariantID = "41455420506305";

export const stagingUKvahaXhardwareVariantID = "41877277868225";
export const stagingUKvahaShardwareVariantID = "41877276065985";
export const stagingUKmembershipVariantID = "37930988699841";
export const stagingUKdeliveryVariantID = "37930988601537";
export const stagingUKpersonalTrainingVariantID = "41094133219521";
export const stagingUKstarterboxVariantID = "37930988568769";
export const stagingUKgarminVariantID = "41245272735937";

// staging DACH values
// deprecated classic device
export const stagingDACHvahaXclassicVariantID = "";
export const stagingDACHvahaSclassicVariantID = "";
// hardware plan
export const stagingDACHvahaXhardwareVariantID = "42423789224087";
export const stagingDACHvahaShardwareVariantID = "42423782539415";

export const stagingDACHvahaShardwareDeviceVariantID = "42487984849047";
export const stagingDACHvahaXhardwareDeviceVariantID = "42487985799319";

export const stagingDACHmembershipVariantID = "35840497025175";
export const stagingDACHdeliveryVariantID = "35840496894103";
export const stagingDACHpersonalTrainingVariantID = "41439316803735";
export const stagingDACHstarterboxVariantID = "35840496468119";
export const stagingDACHgarminVariantID = "41372934832279";

// prod DACH values
export const productionDACHvahaXclassicVariantID = "39356723003470";
export const productionDACHvahaSclassicVariantID = "31644552659022";
export const productionDACHvahaXhardwareVariantID = "43595164844299";
export const productionDACHvahaShardwareVariantID = "43595166974219";
//export const productionDACHmembershipVariantID = "39664371531854";
export const productionDACHmembershipVariantID = "43693853933835";
export const productionDACHdeliveryVariantID = "39530519625806";
export const productionDACHpersonalTrainingVariantID = "39575900389454";
export const productionDACHstarterboxVariantID = "31809039663182";
export const productionDACHgarminVariantID = "39530523426894";

export const productionDACHdumbellVariantID = "43752994537739";
export const productionDACHJabraVariantID = "44003524280587";
export const productionDACHSummerGoodieBag = "44080154640651";

export const productionDACHvahaShardwareDeviceVariantID = "43603097682187";
export const productionDACHvahaXhardwareDeviceVariantID = "43603098632459";

export const productionDACHvahaPlan12MonthUpfrontVahaXVariantID =
  "43743977734411";
export const productionDACHvahaPlan12MonthUpfrontVahaSVariantID =
  "43744026165515";

// prod UK values
export const productionUKvahaXclassicVariantID = "38603968741527";
export const productionUKvahaSclassicVariantID = "39596804079767";
export const productionUKvahaXhardwareVariantID = "42459602682007";
export const productionUKvahaShardwareVariantID = "42459613560983";
export const productionUKmembershipVariantID = "38603968348311";
export const productionUKdeliveryVariantID = "38603967791255";
export const productionUKpersonalTrainingVariantID = "41694599250071";
export const productionUKstarterboxVariantID = "41283229253783";
export const productionUKgarminVariantID = "38603967725719";
export const productionVahaSblackFriday = "43684120658187";
export const productionVahaXblackFriday = "43684153884939";
export const productionUKJabraHeadphones = "42766249853079";
export const productionUKSummerGoodieBag = "42929559928983";

// export const productionDACHHardwareLineItemsArray = [productionDACHvahaXhardwareVariantID,productionDACHvahaShardwareVariantID,productionDACHmembershipVariantID]

// prod AE values
export const productionAEvahaXclassicVariantID = "44300638257469";
export const productionAEDelivery = "44300638421309";
export const productionAEMembership = "44300636750141";
export const productionAEStarterBox = "44300638486845";
export const productionAEPersonalTraining = "44300636881213";

// staging UK hardwareLineItems
export const stagingUKhardwareLineItems = [
  {
    variantId: `gid://shopify/ProductVariant/${stagingUKmembershipVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${stagingUKdeliveryVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${stagingUKpersonalTrainingVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${stagingUKstarterboxVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${stagingUKgarminVariantID}`,
    quantity: 1,
  },
];

// staging DACH hardwareLineItems
export const stagingDACHhardwareLineItems = [
  {
    variantId: `gid://shopify/ProductVariant/${stagingDACHdeliveryVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${stagingDACHpersonalTrainingVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${stagingDACHstarterboxVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${stagingDACHgarminVariantID}`,
    quantity: 1,
  },
];

// production DACH hardwareLineItems
export const productionDACHhardwareLineItems = [
  {
    variantId: `gid://shopify/ProductVariant/${productionDACHmembershipVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionDACHdeliveryVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionDACHpersonalTrainingVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionDACHstarterboxVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionDACHgarminVariantID}`,
    quantity: 1,
  },
];

// production UK hardwareLineItems
export const productionUKhardwareLineItems = [
  {
    variantId: `gid://shopify/ProductVariant/${productionUKmembershipVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionUKdeliveryVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionUKpersonalTrainingVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionUKstarterboxVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionUKgarminVariantID}`,
    quantity: 1,
  },
];

// production AE Line Items
export const productionAELineItems = [
  {
    variantId: `gid://shopify/ProductVariant/${productionAEvahaXclassicVariantID}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionAEDelivery}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionAEMembership}`,
    quantity: 1,
  },
  {
    variantId: `gid://shopify/ProductVariant/${productionAEPersonalTraining}`,
    quantity: 1,
  },
];
