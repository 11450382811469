<template>
  <div class="p-5 basket-wrapper-b basket-flatrate">
    <header
      class="is-flex is-justify-content-space-between is-align-items-center mb-4"
    >
      <h3 class="is-size-4 has-text-weight-bold">
        Einkaufswagen
      </h3>
      <button
        class="button is-transparent p-0 mb-0"
        @click="setSidebarOpen(false)"
      >
        <CloseIcon />
      </button>
    </header>
    <div class="basket-container">
      <div>
        <div
          class="is-flex is-justify-content-space-between is-align-items-flex-end"
        >
          <div class="basket-wrapper">
            <div class="basket-title icon-row">
              <span class="basket-title-span-holder">
                <span
                  ><span class="font-weight-700">Wähle deinen VAHA</span>
                  &nbsp;<em class="font-weight-400">PLAN</em></span
                >
              </span>
              <span v-if="helpMeChooseScoped == true">
                <span
                  @click="closeBasket"
                  v-if="isNuxt == true"
                  class="help-choose"
                >
                  {{ $t("basket.helpMeChoose") }}
                </span>
                <span
                  @click="closeBasket"
                  v-if="isNuxt != true"
                  class="help-choose"
                >
                  <router-link
                    :to="{
                      hash: this.seeMoreLink.hash,
                    }"
                    v-scroll-to="{ el: this.seeMoreLink.hash }"
                    class="underlined"
                    >Produkte vergleichen</router-link
                  >
                </span>
              </span>
            </div>
            <div class="basket-offer-image">
              <img :src="selectedProduct.imageBasket" style="width:100%" />
            </div>
            <div class="basket-vaha-items">
              <div
                v-for="product in products"
                :key="product.type"
                class="basket-vaha-item"
                :class="{
                  active: $t(selectedProduct.id) == $t(product.id),
                  borderWhite:
                    selectedRegionScoped != 'UK' && vahaSEnabled == false,
                  hidden:
                    selectedRegionScoped != 'UK' &&
                    $t(product.id) == 2 &&
                    vahaSEnabled == false,
                  fullWidth:
                    selectedRegionScoped != 'UK' &&
                    $t(product.id) == 1 &&
                    vahaSEnabled == false,
                }"
                @click="
                  changeProduct(product, $t(product.id), selectedRegionScoped)
                "
              >
                <template>
                  <div
                    class="basket-vaha-item-image-wrapper basket-vaha-item-image-wrapper--uk"
                  >
                    <p class="basket-product-title strong font-16">
                      {{ $t(product.name) }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="basket-offer-selector">
          <div
            :class="{
              active: selectedPricingModel == 'ONE_TIME',
            }"
            class="offer offer-one-time"
            @click="setSelectedPricingModel('ONE_TIME')"
          >
            <div class="column-left">
              <img
                class="selector"
                :src="
                  selectedPricingModel === 'ONE_TIME'
                    ? 'https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/radio-checked.png'
                    : 'https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/radio-unchecked.png'
                "
              />
            </div>
            <div class="column-right is-flex is-flex-direction-column">
              <p class="title">
                Einmalzahlung <span class="promo">Bestes Angebot </span>
              </p>
              <p class="description">12-monatige Mitgliedschaft inklusive</p>
              <div class="prices">
                <p class="price-promo">
                  {{ selectedProduct.payUpfront.prices.promotional }}
                </p>
                <p class="price-original">
                  {{ selectedProduct.payUpfront.prices.original }}
                </p>
              </div>
              <!-- <div class="prices">
                <p class="price-promo">
                  {{ selectedProduct.payUpfront.prices.original }}
                </p>
              </div> -->
            </div>
          </div>
          <div
            class="offer offer-installments"
            :class="{
              active: selectedPricingModel == 'INSTALLMENTS',
            }"
            @click="setSelectedPricingModel('INSTALLMENTS')"
          >
            <div>
              <img
                class="selector"
                :src="
                  selectedPricingModel === 'INSTALLMENTS'
                    ? 'https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/radio-checked.png'
                    : 'https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/radio-unchecked.png'
                "
              />
            </div>
            <div class="column-right is-flex is-flex-direction-column">
              <p class="title">
                Ratenzahlung (12 Monate)
              </p>
              <p class="description">Monatliche Zahlungsweise</p>
              <p class="description has-text-grey">
                *12 — monatige Mindestlaufzeit
              </p>
              <div class="prices prices-column">
                <p class="price-mtl">
                  {{ selectedProduct.payUpfront.prices.monthly }}
                </p>
                <p class="price-description">
                  für 12 Monate (inkl. MwSt)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div :class="'separator--light my-4'" />
        <PromoBox
          :variant="promoBoxVariant"
          :selectedProduct="selectedProduct"
          :region="selectedRegionScoped"
          :experimentRunning="experimentRunning"
          :showYellowBox="false"
        />

        <!-- <div :class="'separator--light my-4'" /> -->
        <!-- <div
          class="is-flex is-justify-content-space-between is-align-items-flex-end"
        >
          <BasketCollapse
            :id="'MEMBERSHIP'"
            :title="$t(selectedProduct.membership.headline)"
            :icon="'medal'"
          >
            <div class="basket-wrapper">
              <div
                class="basket-list-price-wrapper is-justify-content-space-between"
              >
                <p class="font-14 pr-3 font-grey">
                  Zusätzliche Mitgliedschaft für 39 €/Monat mit einer
                  Mindestlaufzeit von 12 Monaten. Abrechnung ab Aktivierung
                  deines VAHAs.
                </p>
              </div>
            </div>
          </BasketCollapse>
        </div>
        <div :class="'separator--light my-4'" /> -->

        <NoDiscount
          v-if="activePromotion === 'NO_DISCOUNT'"
          :icon="'star-promo'"
          :region="selectedRegionScoped"
        />
        <!-- <Vahaxmas
          v-if="activePromotion === 'VAHAXMAS'"
          :icon="'star-promo'"
          :region="selectedRegionScoped"
        /> -->
        <!-- <Vahanewyear
          v-if="activePromotion === 'VAHANEWYEAR'"
          :icon="'star-promo'"
          :region="selectedRegionScoped"
        /> -->
        <div
          v-if="
            selectedProductDiscountedPrice != null ||
              discount.code.promoCode != null
          "
          :class="'separator--light my-4'"
        />
        <BasketDiscountRow
          v-if="
            selectedProductDiscountedPrice != null &&
              $te(discount.code.promoCode)
          "
          :discount="discount"
        />
        <!-- <div
          v-if="!$te(discount.code.promoCode)"
          :class="'separator--light my-4'"
        /> -->
        <BasketPricesRowV2
          :prices="prices"
          :selectedRegion="selectedRegionScoped"
          :unique-discount-selected-product="selectedProductDiscountedPrice"
        />
        <div :class="{ fixed: experimentRunning }">
          <!-- <AppIconButton
            v-if="selectedProduct.paymentButton.url"
            class="basket-payments-button"
            theme="yellow"
            :title="$t(selectedProduct.paymentButton.label)"
            @click="
              redirectToShop(
                $t(selectedProduct.paymentButton.url),
                {
                  id: $t(selectedProduct.id),
                  name: $t(selectedProduct.name),
                },
                $t(selectedProduct.id),
                selectedCurrencyScoped,
                selectedRegionScoped,
                false
              )
            "
          /> -->
          <div class="asterisks">
            <p v-if="selectedPricingModel === 'INSTALLMENTS'">
              *für 12 Monate. Nach den ersten 12 Zahlungsmonaten beträgt dein
              monatliches Abonnement € 49 pro Monat.
            </p>
            <p>
              Bei einem Vollkauf werden die 25% direkt abgezogen. Beim VAHA Plan
              erhältst du 25% Nachlass auf die 1. Zahlung sowie bei Bestellung
              eines VAHA S eine Gutschrift in Höhe von 547,25 € bei einem VAHA X
              von 684,75 € nach Ablauf der 30-tägigen Testphase auf dein VAHA
              Mitgliedschaftsprofil. Eine Auszahlung des Betrags ist
              ausgeschlossen. Das Angebot ist bis einschließlich 30.11.2023 und
              ausschließlich für Kunden in DE und AT gültig. Es kann nicht mit
              anderen Angeboten kombiniert werden.
            </p>
          </div>
          <AppIconButton
            v-if="selectedProduct.paymentButton.url"
            class="basket-payments-button"
            theme="yellow"
            :title="$t(selectedProduct.paymentButton.label)"
            @click="prepareShopifyUrl(selectedProduct.id, selectedRegionScoped)"
          />
        </div>
        <div
          class="basket-payments-container experiment-wvSDVbi8R6KyxCB9T0CkrA"
        >
          <img
            v-for="allowedPayment in allowedPayments"
            :key="allowedPayment.name"
            :src="prepareAllowedPaymentName(allowedPayment.name)"
            :alt="`${allowedPayment.name}`"
            class="basket-payments-icon"
          />
        </div>
      </div>

      <div :class="'separator--light my-4'" />
      <PageSectionContainer class="has-background-white pb-5 mb-big" container>
        <ul class="columns is-multiline is-mobile mt-2 mb-3">
          <li
            v-for="item in selectedProduct.experimentFeaturedServices.items"
            :key="item.name"
            class="column is-12-mobile is-12-tablet is-4-desktop"
          >
            <span
              class="vaha-item delivery-notes"
              :class="{ 'experiment-13-09': experimentRunning }"
            >
              <span class="vaha-icon">
                <component
                  :is="`Icon${$t(item.id)}`"
                  :class="`vaha-icon-${$t(item.id)}`"
                />
              </span>
              <span class="vaha-text has-text-centered-desktop">
                {{ $t(item.content) }}
              </span>
            </span>
          </li>
        </ul>
      </PageSectionContainer>
      <!-- <div :class="'separator mb-4 mt-negative-30'" />
      <p class="has-text-left pb-30 font-12">
        {{ $t(selectedProduct.promotions.flatrateFlashSale.footer) }}
      </p> -->
    </div>
  </div>
</template>

<script>
import CloseIcon from "../../icons/x";
import ServiceSection from "../../components/ServiceSection";
import PromoBox from "../../components/PromoBox";
import PageSectionContainer from "../../components/container/PageSectionContainer";
import BasketAccordion from "../../components/BasketAccordion";
import TransformationPackage from "../../components/TransformationPackage";
import BlackFriday from "../../components/BlackFriday";
import AppIconButton from "../../components/AppIconButton";
import IconTesting from "../../icons/trial";
import IconWarranty from "../../icons/guarantee";
import IconDelivery from "../../icons/delivery";
import Icon30Days from "../../icons/30days";
import IconStar from "../../icons/star";
import IconVerticalDivider from "../../icons/vertical-divider";
import createCheckout from "../../mixins/createCheckout";

import NewYearPromo from "../../components/promotions/NewYear.vue";
import { store } from "../../store";
import prepareAllowedPaymentName from "../../mixins/prepareAllowedPaymentName";
import redirectToShop from "../../mixins/redirectToShop";
import changeProduct from "../../mixins/changeProduct";
import closeBasket from "../../mixins/closeBasket";
import setSidebarOpen from "../../mixins/setSidebarOpen";
import BasketDiscountRow from "../../components/BasketDiscountRow.vue";
import BasketPricesRowV2 from "../../components/BasketPricesRowV2.vue";
import BasketCollapse from "../../components/BasketCollapse";
import prepareCheckoutLink from "../../mixins/prepareCheckoutLink";
import { BASE_PRICES } from "../../constants";

import NoDiscount from "../../components/promotions/no-discount.vue";
import Vahaxmas from "../../components/promotions/vahaxmas.vue";
import Vahanewyear from "../../components/promotions/vahanewyear.vue";

export default {
  name: "DACHBasketHardwareUpfront",
  props: {
    allowedPayments: { type: Array, default: () => [] },
    isProductFlatrate: { type: Boolean, default: false },
    helpMeChooseScoped: { type: Boolean },
    isNuxt: { type: Boolean },
    selectedRegionScoped: { type: String, default: "DE" },
    selectedCurrencyScoped: { type: String, default: "EUR" },
    seeMoreLink: { type: Object, default: () => {} },
  },
  mixins: [
    prepareAllowedPaymentName,
    redirectToShop,
    changeProduct,
    closeBasket,
    setSidebarOpen,
    prepareCheckoutLink,
    createCheckout,
  ],
  components: {
    CloseIcon,
    ServiceSection,
    PageSectionContainer,
    IconDelivery,
    Icon30Days,
    IconTesting,
    IconWarranty,
    IconStar,
    IconVerticalDivider,
    AppIconButton,
    BasketAccordion,
    TransformationPackage,
    PromoBox,
    BlackFriday,
    NewYearPromo,
    BasketDiscountRow,
    BasketPricesRowV2,
    BasketCollapse,
    NoDiscount,
    Vahanewyear,
    Vahaxmas,
  },
  computed: {
    selectedPricingModel() {
      return store.state.selectedPricingModel;
    },
    selectedProduct() {
      return store.state.selectedProduct;
    },
    products() {
      return store.state.products;
    },
    activePromotion() {
      return store.state.activePromotion;
    },
    experimentRunning() {
      return store.state.experimentRunning;
    },
    vahaSEnabled() {
      return store.state.vahaSEnabled;
    },
    promoBoxVariant() {
      return store.state.promoBoxVariant;
    },
    discount() {
      const activePromotionCamelCase = store.state.activePromotionCamelCase;
      return store.state.selectedProduct.promotions[activePromotionCamelCase]
        .discount;
    },
    klarnaNon0Enabled() {
      return process.env.VUE_APP_KLARNA_NON_0 === "true" ? true : false;
    },
    prices() {
      const activePromotionCamelCase = store.state.activePromotionCamelCase;
      return store.state.selectedProduct.promotions[activePromotionCamelCase]
        .pricesBeforeAfter;
    },
    selectedProductDiscountedPrice() {
      return store.state.uniqueDiscount.selectedProductDiscountedPrice;
    },
  },
  async mounted() {
    console.log(this.selectedRegionScoped);
    const selectedRegionLowerCase = this.selectedRegionScoped.toLowerCase();
    const activePromotionCamelCase = store.state.activePromotionCamelCase;
    console.log(
      `products.productS.promotions.${activePromotionCamelCase}.discount.code.promoCode`
    );
    console.log(
      this.$t(
        `products.productS.promotions.${activePromotionCamelCase}.discount.code.promoCode`
      )
    );
    const discountData = await this.determineDiscountCodeAndValue(
      this.selectedProduct.type,
      this.selectedRegionScoped
    );

    const payload = { discountData, activePromotionCamelCase };
    store.commit("setDiscountData", payload);
    const { vahaXPrice, vahaSPrice } = discountData;
    const selectedProductType = store.state.selectedProduct.type;
    const selectedProductPriceAfter =
      selectedProductType === "products.productS.type"
        ? vahaSPrice
        : vahaXPrice;

    const vahaXbasePrice = BASE_PRICES[selectedRegionLowerCase].vahaX;
    const vahaSbasePrice = BASE_PRICES[selectedRegionLowerCase].vahaS;
    const pricesPayload = {
      vahaXPrice,
      vahaSPrice,
      selectedProductPriceAfter,
      vahaXbasePrice,
      vahaSbasePrice,
    };
    store.commit("setDynamicPriceAfter", pricesPayload);
  },
  methods: {
    setSelectedPricingModel(method) {
      store.commit("setSelectedPricingModel", method);
      console.log(method);
    },
  },
};
</script>

<style lang="scss">
.new-promo {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  margin: 15px auto;
  background: #f5f5f5;

  p {
    font-weight: 700;
    color: #929292;
  }
}

.basket-offer-selector .offer {
  border: 1px solid #e2e9ee;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;
  &.active {
    border-color: #feba50;
  }

  .column-right {
    margin-left: 10px;
    margin-top: -2px;
  }

  .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;

    .promo {
      background: #ffd26e;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .description {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .prices {
    display: flex;
    align-items: center;

    &.prices-column {
      flex-direction: column;
      align-items: flex-start;
    }

    .price-promo {
      font-size: 20px;
      font-weight: 700;
      color: #feba50;
      margin-right: 10px;
    }
    .price-original {
      font-size: 18px;
      font-weight: 400;
      color: #777e90;
      text-decoration: line-through;
    }

    .price-mtl {
      font-weight: 700;
      font-size: 22px;
    }
    .price-description {
      font-size: 18px;
      font-weight: 400;
    }
  }
  .selector {
    width: 16px;
  }
}

.asterisks {
  p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 15px;
    color: #828282;
  }
}
</style>
